import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function UnderConstruction() {
  return (
    <Container fluid className="project-section">
        <h1>
        <div>We are currently working on this page. Please check back later.</div>
        </h1>
    </Container>
  );
}

export default UnderConstruction;
