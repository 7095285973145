import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Stefan Haller </span>
            from <span className="purple"> Vorarlberg, Austria.</span>
            <br />
            I currently attend the HTL-Dornbirn in the fifth class.
            <br />
            Also I am working on my diploma thesis in collaboration with the company <b>Tridonic</b> .
            <br />
            <br />
            Apart from coding and improving my skills, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Going out in the Nature
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> Learning about different cultures
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Ach du scheiß'n, nöd schu widda so a hurra BUG"{" "}
          </p>
          <footer className="blockquote-footer">Stefan</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
